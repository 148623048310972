<template>
    <div class="b2c-page-bg">
        <div :class="`b2c-pc-header ${pageStep === 3 ? 'b2c-pc-header-pc' : ''}`">
            <div class="b2c-pc-header-tittle"><a href="#">
              <feather-icon size="24" icon="ChevronLeftIcon"/>
            </a>
            <span>Giỏ hàng & Thanh toán</span>
            </div>
            <div class="nav2"></div>
        </div>
        <div class="installment-cart-page-content" v-if="pageStep === 1 || pageStep === 2">
            <div class="row">
              <div class="col-md-7 col-sm-12">
                <div class="order-info-col">
                    <div class="order-col-bg"></div>
                    <div class="text-order-info-tittle">Thông tin đơn hàng</div>
                    <b-card body-class="p-0" class="mt-20px card-sim-info">
                        <div class="d-flex justify-content-between gap-18px card-sim-info-top">
                            <div>
                                <div class="text-sim-num">
                                    087 038 38 68
                                </div>
                                <div class="d-flex mt-8px">
                                    <div class="text-label">
                                        Giá SIM số: 
                                    </div>
                                    <div class="ml-18px">
                                        <div class="text-price">
                                            199.000đ
                                        </div>
                                        <div class="text-price-line-through">
                                            220.000đ
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex mt-8px">
                                    <div class="text-label">
                                        Giá gói:
                                    </div>
                                    <div class="ml-18px">
                                        <div class="text-price">
                                            199.000đ/tháng
                                        </div>
                                        <div class="text-price-line-through">
                                            220.000đ
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex mt-8px">
                                    <div class="text-label">
                                        Phụ phí eSIM:
                                    </div>
                                    <div class="ml-18px">
                                        <div class="text-price">
                                            0đ
                                        </div>
                                        <div class="text-price-line-through">
                                            40.000đ
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="text-pack-name">
                                ITEL<br />199
                            </div>
                        </div>
                        <div class="card-sim-info-bottom">
                            <label class="checkbox-term">
                                <input type="checkbox" checked="checked">
                                <span class="checkmark"></span>
                                <div>
                                    <div class="text-use-esim">
                                        Sử dụng eSIM
                                    </div>
                                    <div class="text-only-use-esim mt-4px">
                                        Chỉ dùng cho các dòng máy hỗ trợ eSIM
                                    </div>
                                </div>
                            </label>
                            <div class="d-flex justify-content-end mt-6px">
                                <button class="btn-delete">
                                    Xóa
                                </button>
                            </div>
                        </div>
                    </b-card>
                    <div class="mt-48px card-add-more">
                        <span>
                            Chọn thêm số
                        </span>
                        <img src="../assets/images/lookup/plus.png" alt="" />
                    </div>
                </div>

              </div>
              <div class="col-md-5 col-sm-12">
                <b-card class="p-0 card-receive-info" body-class="p-0">
                  <b-card-header class="card-header">Thông tin người nhận</b-card-header>
                  <b-card-text>
                    <validation-observer ref="infoValid">
                        <div class="row p-2">
                            <div class="col-md-12">
                                <validation-provider #default="{ errors }" rules="required|min:3|space">
                                    <label class="d-block">Họ và tên <span class="text-primary">*</span></label>
                                    <b-form-input size="sm1" maxlength="35" placeholder="(Trùng khớp với hồ sơ trả góp và đăng kí thuê bao)" :state="errors.length > 0 ? false : null"/>
                                    <small class="fs-error-txt" v-if="errors.length > 0">Vui lòng nhập đầy đủ họ và tên người nhận</small>
                                </validation-provider>
                            </div>
                            <div class="col-md-12">
                                <label class="d-block">Ngày/tháng/năm sinh <span class="text-primary">*</span></label>
                                <div class="select-row">
                                    <div class="select-container p-0">
                                        <v-select
                                            class="select-date"
                                            label="text"
                                            :filter="fuseSearch"
                                            :options="[]"
                                            :reduce="option => option.id"
                                            placeholder="Ngày"
                                        />
                                    </div>
                                    <div class="select-container p-0">
                                        <v-select
                                            class="select-date"
                                            label="text"
                                            :filter="fuseSearch"
                                            :options="[]"
                                            :reduce="option => option.id"
                                            placeholder="Tháng"
                                        />
                                    </div>
                                    <div class="select-container p-0">
                                        <v-select
                                            class="select-date"
                                            label="text"
                                            :filter="fuseSearch"
                                            :options="[]"
                                            :reduce="option => option.id"
                                            placeholder="Năm sinh"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <validation-provider #default="{ errors }" rules="required">
                                    <label class="d-block">Điện thoại liên lạc <span class="text-primary">*</span></label>
                                    <b-form-input type="text"
                                        placeholder="Số điện thoại (Đang sử dụng)"
                                        oninput="this.value = this.value.replace(/[^0-9.]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');"
                                        maxlength="10" size="sm1"
                                        :state="errors.length > 0 ? false : null"/>
                                    <small class="fs-error-txt" v-if="errors.length > 0">Vui lòng nhập số điện thoại liên hệ</small>
                                </validation-provider>
                            </div>
                            <div class="col-md-12">
                                <validation-provider rules="">
                                    <label class="d-block">E-mail <span class="text-primary">*</span></label>
                                    <b-form-input 
                                        size="sm1" 
                                        maxlength="40"
                                        placeholder="E-mail (Đang sử dụng)"
                                    />
                                </validation-provider>
                            </div>
                        </div>
                        <div class="text-card-tittle">
                            Giao hàng tận nơi
                        </div>
                        <div class="row p-2">
                            <div class="col-md-12">
                                <validation-provider #default="{ errors }" rules="required">
                                    <label class="d-block">Địa chỉ <span class="text-primary">*</span></label>
                                    <b-form-input size="sm1" maxlength="35" placeholder="Địa chỉ" :state="errors.length > 0 ? false : null"/>
                                    <small class="fs-error-txt" v-if="errors.length > 0">Vui lòng nhập đầy đủ địa chỉ</small>
                                </validation-provider>
                            </div>
                            <div class="col-md-6">
                                <validation-provider #default="{ errors }" rules="required">
                                    <label class="d-block">Tỉnh / TP <span class="text-primary">*</span></label>
                                    <v-select
                                        class="select-date"
                                        label="text"
                                        :filter="fuseSearch"
                                        :options="[]"
                                        :reduce="option => option.id"
                                        placeholder="Tỉnh / TP"
                                    />
                                    <small class="fs-error-txt" v-if="errors.length > 0">Vui lòng chọn Tỉnh/ TP</small>
                                </validation-provider>
                            </div>
                            <div class="col-md-6">
                                <validation-provider #default="{ errors }" rules="required">
                                    <label class="d-block">Quận / Huyện <span class="text-primary">*</span></label>
                                    <v-select
                                        class="select-date"
                                        label="text"
                                        :filter="fuseSearch"
                                        :options="[]"
                                        :reduce="option => option.id"
                                        placeholder="Quận / Huyện"
                                    />
                                    <small class="fs-error-txt" v-if="errors.length > 0">Vui lòng chọn Quận / Huyện</small>
                                </validation-provider>
                            </div>
                            <div class="col-md-12">
                                <validation-provider #default="{ errors }" rules="required">
                                    <label class="d-block">Phường / xã <span class="text-primary">*</span></label>
                                    <v-select
                                        class="select-date"
                                        label="text"
                                        :filter="fuseSearch"
                                        :options="[]"
                                        :reduce="option => option.id"
                                        placeholder="Phường / xã"
                                    />
                                    <small class="fs-error-txt" v-if="errors.length > 0">Vui lòng chọn Phường / xã</small>
                                </validation-provider>
                            </div>
                            <div class="mt-43px col-md-12 mb-20px" v-if="pageStep === 1">
                                <button class="btn-continue" @click="pageStep = 2">
                                    Tiếp tục
                                </button>
                            </div>
                        </div>
                    </validation-observer>
                  </b-card-text>
                </b-card>
                <b-card class="p-0 card-receive-info" body-class="p-0" v-if="pageStep === 2">
                  <b-card-header class="card-header">Chọn hình thức thanh toán</b-card-header>
                  <b-card-text>
                        <div class="row p-2 border-bottom">
                            <div class="col-md-12">
                                <div class="payment-container">
                                    <div :class="`payment-card ${paymentMethod == 'VNPAY' ? 'active' : ''}`" @click="paymentMethod = 'VNPAY'">
                                        <label class="checkbox-container">
                                            <input type="checkbox" :checked="paymentMethod == 'VNPAY'" @click="$event.preventDefault()">
                                            <span class="checkmark"></span>
                                        </label>
                                        <div class="d-flex justify-content-between align-items-center gap-8px w-auto flex-auto">
                                            <div>
                                                <div class="text-payment-info">
                                                    Thanh toán qua VNPAY
                                                </div>
                                                <div class="text-payment-description mt-3px">
                                                    Thanh toán bằng QR code
                                                </div>
                                            </div>
                                            <img src="../assets/images/lookup/vnpay.svg" alt="" />
                                        </div>
                                    </div>
                                    <div :class="`payment-card mt-15px ${paymentMethod == 'BANK' ? 'active' : ''}`" @click="paymentMethod = 'BANK'">
                                        <label class="checkbox-container">
                                            <input type="checkbox" :checked="paymentMethod == 'BANK'" @click="$event.preventDefault()">
                                            <span class="checkmark"></span>
                                        </label>
                                        <div class="d-flex justify-content-between align-items-center gap-8px w-auto flex-auto">
                                            <div>
                                                <div class="text-payment-info">
                                                    Qua thẻ ATM
                                                </div>
                                                <div class="text-payment-description mt-3px">
                                                    Có hỗ trợ Internet Banking
                                                </div>
                                            </div>
                                            <img src="../assets/images/lookup/atm.svg" alt="" />
                                        </div>
                                    </div>
                                    <div :class="`payment-card mt-15px ${paymentMethod == 'INTCARD' ? 'active' : ''}`" @click="paymentMethod = 'INTCARD'">
                                        <label class="checkbox-container">
                                            <input type="checkbox" :checked="paymentMethod == 'INTCARD'" @click="$event.preventDefault()">
                                            <span class="checkmark"></span>
                                        </label>
                                        <div class="d-flex justify-content-between align-items-center gap-8px w-auto flex-auto">
                                            <div>
                                                <div class="text-payment-info">
                                                    Qua thẻ thanh toán quốc tế
                                                </div>
                                                <div class="text-payment-description mt-3px">
                                                    Thanh toán qua thẻ thanh toán quốc tế
                                                </div>
                                            </div>
                                            <img src="../assets/images/lookup/visa.svg" alt="" />
                                        </div>
                                    </div>
                                    <div :class="`payment-card mt-15px ${paymentMethod == 'ZALO' || paymentMethod == 'MOMO' ? 'active' : ''}`">
                                        <label class="checkbox-container">
                                            <input type="checkbox" :checked="paymentMethod == 'ZALO' || paymentMethod == 'MOMO'" @click="$event.preventDefault()">
                                            <span class="checkmark"></span>
                                        </label>
                                        <div class="d-flex justify-content-between align-items-center gap-8px w-auto flex-auto">
                                            <div>
                                                <div class="text-payment-info">
                                                Thanh toán qua ví điện tử
                                                </div>
                                                <div class="text-payment-description mt-3px">
                                                    Thanh toán qua ZaloPay, Momo
                                                </div>
                                            </div>
                                            <img src="../assets/images/lookup/phone.svg" alt="" />
                                        </div>
                                        <div class="wallet-info-list">
                                            <div class="wallet-info-container" @click="paymentMethod = 'MOMO'">
                                                <div class="wallet-info-container-img">
                                                    <img src="../assets/images/lookup/momo.svg" alt="" />
                                                </div>
                                                <div class="d-flex justify-content-between align-items-center gap-8px w-auto flex-auto">
                                                    <span class="wallet-info-name">
                                                        Ví điện tử Momo
                                                    </span>
                                                    <img src="../assets/images/lookup/tick.png" alt="" v-if="paymentMethod == 'MOMO'"/>
                                                </div>
                                            </div>
                                            <div class="wallet-info-container" @click="paymentMethod = 'ZALO'">
                                                <div class="wallet-info-container-img">
                                                    <img src="../assets/images/lookup/zalo.svg" alt="" />
                                                </div>
                                                <div class="d-flex justify-content-between align-items-center gap-8px w-auto flex-auto">
                                                    <span class="wallet-info-name">
                                                        Ví điện tử ZaloPay
                                                    </span>
                                                    <img src="../assets/images/lookup/tick.png" alt="" v-if="paymentMethod == 'ZALO'"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-43px col-md-12 text-installment-info">
                                Thông tin trả góp
                            </div>
                        </div>
                        <div class="row p-2">
                            <div class="col-md-12">
                                <div class="d-flex justify-content-between">
                                    <div class="text-cart-label">
                                        Phí vận chuyển
                                    </div>
                                    <div>
                                        <div class="text-cart-value">
                                            0đ
                                        </div>
                                        <div class="text-cart-line-through">
                                            49.000đ
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="bg-cart pl-15 pr-15">
                            <div class="d-flex justify-content-between pt-1 pb-1">
                                <div class="text-cart-label">
                                    Khoản trả trước
                                </div>
                                <div class="text-cart-value">
                                    123đ
                                </div>
                            </div>
                            <div class="d-flex justify-content-between pt-1 pb-1">
                                <div class="text-cart-label">
                                    Khoản trả góp
                                </div>
                                <div class="text-cart-value">
                                    123đ
                                </div>
                            </div>
                            <div class="d-flex justify-content-between pt-1 pb-1 border-bottom">
                                <div class="text-cart-label">
                                    Số tháng trả góp
                                </div>
                                <div class="text-cart-value">
                                    12 tháng
                                </div>
                            </div>
                            <div class="d-flex justify-content-between align-items-center pt-1 pb-1">
                                <div class="text-cart-label">
                                    Số tiền cần thanh toán
                                </div>
                                <div class="text-cart-total">
                                    123đ
                                </div>
                            </div>
                        </div>
                        <div class="row p-2 mt-20px">
                            <div class="col-md-12">
                                <button class="btn-continue" @click="pageStep = 3">
                                    THANH TOÁN
                                </button>
                            </div>
                            <div class="mt-14px text-term">
                                Bằng cách bấm thanh toán, bạn đồng ý với <a href="#">chính sách mua hàng</a> của iTel
                            </div>
                        </div>
                  </b-card-text>
                </b-card>
              </div>
            </div>
        </div>
        <template v-if="pageStep === 3">
            <div class="installment-cart-page-content">
                <b-card class="complete card-order-lookup-success">
                    <div class="col-md-12">
                        <div class="pb-3 pt-3 w-100 text-center">
                            <h2 class="huge-text" style="color: #424242">
                                <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M117.143 54.7756V60.0327C117.136 72.3551 113.146 84.3451 105.768 94.2146C98.3895 104.084 88.0188 111.304 76.202 114.798C64.3853 118.292 51.7558 117.872 40.197 113.602C28.6382 109.331 18.7695 101.439 12.0626 91.1017C5.35581 80.7643 2.17024 68.5359 2.98101 56.2402C3.79179 43.9445 8.55547 32.2403 16.5616 22.8731C24.5677 13.506 35.3873 6.97775 47.4068 4.26212C59.4262 1.54648 72.0015 2.78892 83.2572 7.80414M117.143 14.3185L60 71.5185L42.8571 54.3756"
                                        stroke="#40E108" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                <br />
                                <span class="text-thanks">
                                    Cảm ơn bạn đã thanh toán thành công
                                </span>
                                <br/>
                                <div class="mt-18px text-thanks-info">
                                    Quý Khách sẽ nhận được tin nhắn thông tin
                                    về số điện thoại
                                </div>
                            </h2>
                        </div>
                        <b-card>
                            <div class="p-0">
                                <div class="detail-order-isp p-2">
                                    <b-row class="pb-1">
                                        <b-col cols="6" class="h2 text" style="color: #333333">Mã khoản trả góp:</b-col>
                                        <b-col cols="6" class="pl-0 h1 text-right text-primary" style="font-size: 1.6rem; font-weight: 700">
                                            iTel12345
                                        </b-col>
                                    </b-row>
                                    <b-row class="pt-0 pb-1">
                                        <b-col cols="6" class="h2 text" style="color: #333333">Số thuê bao trả góp:</b-col>
                                        <b-col cols="6" class="h1 text-right" style="font-size: 1.6rem;color: black">
                                        09xxxx9999
                                        </b-col>
                                    </b-row>
                                    <b-row class="pt-0 pb-1">
                                        <b-col cols="6" class="h2 text" style="color: #333333">Họ và tên chủ thuê bao:</b-col>
                                        <b-col cols="6" class="h1 text-right" style="font-size: 1.6rem;color: black">
                                        Nguyễn Văn A
                                        </b-col>
                                    </b-row>
                                    <b-row class="pt-0 pb-1 border-bottom">
                                        <b-col cols="6" class="h2 text" style="color: #333333">Số tiền đã thanh toán</b-col>
                                        <b-col cols="6" class="h1 text-right" style="font-size: 1.6rem;color: black">
                                        1.234.567đ
                                        </b-col>
                                    </b-row>
                                    <b-row class="pt-1">
                                        <b-col cols="12" class="b2c-price-texts mt-1 text" tyle="color: #000000">Người mua hàng:</b-col>
                                        <b-col cols="12" class="h2 text-isp-info" style="color: #000000">Phạm Đức Trung</b-col>
                                        <b-col cols="12" class="b2c-price-texts mt-1 text" tyle="color: #000000">Số điện thoại liên hệ:</b-col>
                                        <b-col cols="12" class="h2 text-isp-info" style="color: #000000">0909999****</b-col>
                                        <b-col cols="12" class="b2c-price-texts mt-1 text" tyle="color: #000000">Email:</b-col>
                                        <b-col cols="12" class="h2 text-isp-info" style="color: #000000">ductrung11***@gmail.com</b-col>
                                        <b-col cols="12" class="b2c-price-texts mt-1 text" tyle="color: #000000">Địa chỉ nhận hàng:</b-col>
                                        <b-col cols="12" class="h2 text-isp-info" style="color: #000000">B018, The Manor, Mễ Trì, Hà Nội</b-col>
                                    </b-row>
                                </div>
                            </div>
                        </b-card>
                        <b-row class="mt-18px">
                            <b-col cols="12" class="h2 text text-center" style="font-size: 1.6rem;color: #424242; margin-bottom: 0">
                                Xem thông tin đơn hàng 
                                <a href="#" class="text-primary text-decoration-underline"
                                        style="font-style: italic" target="_blank">tại đây</a></b-col>
                        </b-row>
                        <SurveyCES phone="01234567809" orderId="123456789"/>
                    </div>
                </b-card>
            </div>
        </template>
    </div>
</template>
<script>
import Vue from "vue";
import {
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BButton,
    BCard,
    BRow,
    BCol
} from "bootstrap-vue"
import {required, min, email} from "@validations";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import {ValidationProvider, ValidationObserver, extend} from "vee-validate";
import vSelect from "vue-select";
import SurveyCES from "@/components/SurveyCES.vue"

Vue.component("v-select", vSelect);

export default {
    components: {
        BFormInput,
        BInputGroup,
        BInputGroupPrepend,
        BButton,
        BCard,
        BRow,
        BCol,
        FeatherIcon,
        ValidationProvider,
        ValidationObserver,
        SurveyCES
    },
    data() {
        return {
            required, 
            min, 
            email,
            pageStep: 1,
            paymentMethod: "VNPAY"
        }
    },
    mounted(){

    },
    methods: {
        fuseSearch(options, search) {
            const fuse = new Fuse(options, {
                keys: ["text", "text1"],
                shouldSort: true,
            });
            return search.length
                ? fuse.search(search).map(({item}) => item)
                : fuse.list;
        },
    }
}
</script>
<style lang="scss">
.select-date{
    .vs__dropdown-toggle{
        height: 50px;
        border: 1px solid rgba(228, 228, 228, 0.6);
        border-radius: 5px;
    }
    .vs__search{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #151522;
        &::placeholder{
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: #B4B4B4;
        }
    }
    .vs__selected{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #151522;
    }
}
</style>
<style lang="scss" scoped>
.b2c-page-bg {
    background: rgba(242, 242, 242, 1);
}
.bg-cart{
    background: #FFF2EC;
}
.b2c-pc-header {
  max-width: 122.4rem;
  margin-left: auto;
  margin-right: auto;
  height: 70px;
  border-bottom: 1px solid #dadada;
  width: 100%;
}

.b2c-pc-header {
  display: flex;
  text-transform: uppercase;
  align-items: center;
}

.b2c-pc-header .b2c-pc-header-tittle {
    span{
        font-size: 24px;
        font-weight: 700;
        line-height: 69px;
        color: #424242;
    }
    display: flex;
    align-items: center;
    width: 50%;
}
.border-bottom{
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.pl-15{
    padding-left: 1.5rem;
}
.pr-15{
    padding-right: 1.5rem;
}
.mt-4px{
    margin-top: 4px;
}
.mt-6px{
    margin-top: 6px;
}
.mt-8px{
    margin-top: 8px;
}
.mt-14px{
    margin-top: 14px;
}
.mt-15px{
    margin-top: 15px;
}
.mt-18px{
    margin-top: 18px;
}
.mt-20px{
    margin-top: 20px;
}
.mt-35px{
    margin-top: 43px;
}
.mt-43px{
    margin-top: 43px;
}
.mt-48px{
    margin-top: 48px;
}
.ml-18px{
    margin-left: 18px;
}
.mb-20px{
    margin-bottom: 20px;
}
.gap-18px{
    gap: 18px;
}
.gap-8px{
    gap: 8px;
}
.flex-auto{
    flex: auto;
}
.installment-cart-page-content{
    padding: 50px 0;
    max-width: 122.4rem;
    margin-left: auto;
    margin-right: auto;
    .order-info-col{
        border-radius: 16px;
        width: 100%;
        // height: 230px;
        padding: 30px 0;
        position: relative;
        .order-col-bg{
            background: #ED1F24;
            border-radius: 16px;
            width: 100%;
            height: 230px;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
        }
        .text-order-info-tittle{
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 36px;
            text-align: center;
            color: #FFFFFF;
            position: relative;
        }
        .card-add-more{
            width: 450px;
            max-width: calc(100% - 46px);
            margin-left: auto;
            margin-right: auto;
            display: flex;
            justify-content: space-between;
            padding: 27px 37px;
            background: #FFFFFF;
            box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
            border-radius: 16px;
            cursor: pointer;

            span{
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                line-height: 23px;
                color: #424242;
            }
        }
        .card-sim-info{
            width: 450px;
            max-width: calc(100% - 46px);
            margin-left: auto;
            margin-right: auto;
            padding: 0;
            background: #FFFFFF;
            box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
            border-radius: 20px !important;
            .card-sim-info-top{
                padding: 15px 34px 18px 34px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            }
            .card-sim-info-bottom{
                padding: 23px 34px 12px 34px;
            }
            .text-sim-num{
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 500;
                font-size: 30px;
                line-height: 28px;
                color: #000000;
            }
            .text-label{
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
                color: #424242;

                min-width: 90px;
            }
            .text-price{
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 16px;
                color: #424242;
            }
            .text-price-line-through{
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
                text-decoration-line: line-through;
                color: #424242;
            }
            .text-pack-name{
                font-family: 'Roboto';
                font-style: italic;
                font-weight: 900;
                font-size: 24px;
                line-height: 92%;
                text-align: center;
                color: #FFFFFF;

                padding: 14px;
                background: #18D3C9;
                box-shadow: 0px 24px 48px -18px rgba(114, 114, 114, 0.2);
                border-radius: 10px;
                height: fit-content;
            }
            .checkbox-term{
                -webkit-appearance: checkbox;
                max-width: 100%;
                margin-left: auto;
                margin-right: auto;
                height: fit-content;
                min-height: 24px;
            }
            .checkbox-term {
                display: block;
                position: relative;
                padding-left: 42px;
                cursor: pointer;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
            }
            .checkbox-term input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;
            }
            .checkmark {
                position: absolute;
                top: 0;
                left: 0;
                height: 24px;
                width: 24px;
                background-color: #eee;
                width: 24px;
                height: 24px;
                box-shadow: 0px 4px 4px rgba(50, 50, 71, 0.08), 0px 4px 8px rgba(50, 50, 71, 0.06);
                border-radius: 6px;
            }
            .checkbox-term:hover input ~ .checkmark {
                background-color: #ccc;
            }
            .checkbox-term input:checked ~ .checkmark {
                background-color: #ED1F24;
            }
            .checkmark:after {
            content: "";
                position: absolute;
                display: none;
            }
            .checkbox-term input:checked ~ .checkmark:after {
                display: block;
            }
            .checkbox-term .checkmark:after {
                left: 9px;
                top: 5px;
                width: 5px;
                height: 10px;
                border: solid white;
                border-width: 0 3px 3px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }
            .text-use-esim{
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 120%;
                color: #333333;
            }
            .text-only-use-esim{
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
                color: #424242;
            }
            .btn-delete{
                width: 100.97px;
                height: 35.71px;
                background: #FFFFFF;
                border: 1px solid #ED1F24;
                border-radius: 26.8042px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 11px;
                color: #ED1F24;
            }
        }
    }
}
.card-receive-info{
    border-radius: 16px !important;
    .card-header{
        padding: 10px 16px;
        background: #F8F8F8;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 25px;
        color: #222222;
        border-radius: 16px 16px 0px 0px;
    }
    label{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 20px;
        color: #151522;
    }
    .form-control{
        height: 50px !important;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #000000;
        border-radius: 5px !important;
        border: 1px solid rgba(228, 228, 228, 0.6) !important;
        &::placeholder{
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: #B4B4B4;
        }
    }
    .select-row{
        display: flex;
        gap: 4px;

        .select-container{
           flex: 33.3%;
        }
    }
    .text-card-tittle{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 25px;
        color: #222222;
        padding: 10px 16px;
        background: #F8F8F8;
    }
    .btn-continue{
        background: #ED1F24;
        border-radius: 26.8042px;
        width: 100%;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;

        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 11px;
        color: #FFFFFF;
    }
    .payment-container{
        width: 380px;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
        .payment-card{
            padding: 14px 19px;
            background: #FFFFFF;
            border: 1px solid #DADADA;
            border-radius: 10px;
            display: flex;
            gap: 15px;
            align-items: center;
            flex-wrap: wrap;
            cursor: pointer;
            &.active{
                background: #FFF6F6;
                border: 1px solid #ED1F24;
            }
            .checkbox-container{
                -webkit-appearance: checkbox;
                height: 17px;
                width: 17px;
            }
            .checkbox-container {
                display: block;
                position: relative;
                // padding-left: 35px;
                cursor: pointer;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
            }
            .checkbox-container input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;
            }
            .checkmark {
                position: absolute;
                top: 0;
                left: 0;
                height: 17px;
                width: 17px;
                background-color: #FFFFFF;
                border-radius: 50%;
                border: 1.2px solid #BBBBBB;
            }
            .checkbox-container:hover input ~ .checkmark {
                background-color: #FFFFFF;
            }
            .checkbox-container input:checked ~ .checkmark {
                border: none;
                background-color: #ED1F24;
            }
            .checkmark:after {
            content: "";
                position: absolute;
                display: none;
            }
            .checkbox-container input:checked ~ .checkmark:after {
                display: block;
            }
            .checkbox-container .checkmark:after {
                width: 7px;
                height: 7px;
                border-radius: 50%;
                background: #FFFFFF;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
            }
            .text-payment-info{
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
                color: #4B4B4B;
            }
            .text-payment-description{
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 300;
                font-size: 9px;
                line-height: 11px;
                color: #4B4B4B;
            }
            .wallet-info-list{
                width: 100%;
                padding: 0 34px;
            }
            .wallet-info-container{
                width: 100%;
                display: flex;
                gap: 11px;
                align-items: center;
                cursor: pointer;
                padding-right: 8px;
                &:first-child{
                    padding-bottom: 8px;
                    border-bottom: 1px solid #DADADA;
                }
                &:last-child{
                    padding-top: 12px;
                }
                .wallet-info-container-img{
                    width: 51px;
                    display: flex;
                    justify-content: center;
                }
                .wallet-info-name{
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 10px;
                    line-height: 12px;
                    color: #4B4B4B;
                }
            }
        }
    }
    .text-installment-info{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 25px;
        color: #222222;
    }
    .text-cart-label{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #424242;
    }
    .text-cart-value{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        text-align: right;
        color: #424242;
    }
    .text-cart-line-through{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        text-align: right;
        text-decoration-line: line-through;
        color: #424242;
    }
    .text-cart-total{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
        text-align: right;
        color: #ED1F24;
    }
    .text-term{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        width: 300px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        a{
            color: #ED1F24;
            cursor: pointer;
        }
    }
}
.card-order-lookup-success{
    max-width: 505px;
    margin-left: auto;
    margin-right: auto;
    .text-thanks{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 41px;
        color: #424242;
    }
    .text-thanks-info{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        color: #424242;
        max-width: 320px;
        white-space: break-spaces;
        margin-left: auto;
        margin-right: auto;
    }
    .card{
        box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important;
        margin-bottom: 0;
        padding: 22px;
    }
}
@media screen and (max-width: 768px) {
    .b2c-pc-header-pc{
        display: none !important;
    }
    .b2c-pc-header {
        height: fit-content;
        width: 100%;
        background: #EC1F24;
        backdrop-filter: blur(13.5914px);
        border-bottom: none;
    }

    .b2c-pc-header {
        display: flex;
        flex-wrap: wrap;
        text-transform: none;
        .nav2{
            background-image: url('../assets/images/lookup/step.svg');
	        height: 42px;
            width: 100%;
            background-color: #D71A1F;
        }
    }

    .b2c-pc-header .b2c-pc-header-tittle {
        height: 50px;
        justify-content: start;
        display: flex;
        align-items: center;
        width: 100%;
        text-align: center;
        padding: 0 25px;

        a{
            width: 15px;
        }
        span{
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 700;
            font-size: 17px;
            line-height: 22px;
            color: #FFFFFF;

            width: 100%;
            text-align: center;
        }
    }
    .installment-cart-page-content{
        padding: 0;
        .order-info-col{
            border-radius: 0;
            background: #FFFFFF;
            .order-col-bg{
                height: 180px;
                border-radius: 0;
            }
            .card-add-more{
                background: #FFFFFF;
                box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
                border-radius: 8px !important;
            }
            .card-sim-info{
                background: #FFFFFF;
                box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
                border-radius: 8px !important;
                .card-sim-info-top{
                    padding: 23px 17px 12px 17px;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                }
                .text-label{
                    min-width: unset;
                }
            }
        }
    }
    .card-receive-info{
        border-radius: 0 !important;
        margin-bottom: 0 !important;
        .card-header{
            border-radius: 0 !important;
        }
    }
    .card-order-lookup-success{
        border: none !important;
        border-radius: 0 !important;
        box-shadow: none; 
        margin-bottom: 0;
        padding-bottom: 39px;
    }
}
</style>